import React, { Suspense } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useMode } from "./theme";
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import {
  COMMON_MODULE,
  AUTH_MODULE,
  HALL_MODULE,
} from "./constants/routerPath";
import { useDispatch, useSelector } from "react-redux";

const Home = React.lazy(() => import("./layouts/Home"));
const Login = React.lazy(() => import("./layouts/Login"));
const Dashboard = React.lazy(() => import("./containers/dashboard"));
const Halls = React.lazy(() => import("./containers/Halls/viewHalls"));
const PartnerPage = React.lazy(() => import("./containers/PartnerPage"));
const CustomerPage = React.lazy(() => import("./containers/CustomerPage"));
const ForgetPassword = React.lazy(() => import("./containers/forgetPassword"));
const ViewPartner = React.lazy(() =>
  import("./containers/Partner/viewPartner")
);

const ViewProfile = React.lazy(() => import("./containers/Pofile/viewProfile"));
const ViewCustomer = React.lazy(() => import("./containers/Customers"));
const LoadingLogo = React.lazy(() => import("./components/LoadingLogo"));
const ChangePassword = React.lazy(() => import("./containers/ChangePassword"));
const isAuthenticated = localStorage.getItem("isLoggedIn");

console.log("this", isAuthenticated, window.location.pathname);
function App() {
  const [theme] = useMode();
  const authData = useSelector((state) => state.auth.data);
  const { _id } = authData?.user || "";
  console.log({ authData, _id });

  const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            index
            path={AUTH_MODULE.LOGIN}
            element={
              <Suspense fallback={<LoadingLogo />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            index
            path={AUTH_MODULE.FORGOT_PASSWORD}
            element={
              <Suspense fallback={<LoadingLogo />}>
                <ForgetPassword />
              </Suspense>
            }
          />
          <Route
            index
            path={COMMON_MODULE.ROOT}
            element={
              <Suspense fallback={<LoadingLogo />}>
                <PartnerPage />
              </Suspense>
            }
          />
          <Route
            index
            path={COMMON_MODULE.CUSTOMER_PAGE}
            element={
              <Suspense fallback={<LoadingLogo />}>
                <CustomerPage />
              </Suspense>
            }
          />
          <Route path={COMMON_MODULE.ROOT} element={<Home />}>
            <Route
              index
              path={COMMON_MODULE.DASHBOARD}
              element={
                <PrivateRoute auth={{ isAuthenticated: _id }}>
                  <Suspense fallback={<LoadingLogo />}>
                    <Dashboard />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              index
              path={COMMON_MODULE.VIEW_PARTNER}
              element={
                <PrivateRoute auth={{ isAuthenticated: _id }}>
                  <Suspense fallback={<LoadingLogo />}>
                    <ViewPartner />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              index
              path={COMMON_MODULE.VIEW_CUSTOMERS}
              element={
                <PrivateRoute auth={{ isAuthenticated: _id }}>
                  <Suspense fallback={<LoadingLogo />}>
                    <ViewCustomer />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              index
              path={COMMON_MODULE.VIEW_PROFILE}
              element={
                <PrivateRoute auth={{ isAuthenticated: _id }}>
                  <Suspense fallback={<LoadingLogo />}>
                    <ViewPartner />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              index
              path={COMMON_MODULE.PROFILE}
              element={
                <Suspense fallback={<LoadingLogo />}>
                  <ViewProfile />
                </Suspense>
              }
            />
            <Route
              index
              path={COMMON_MODULE.CHANGE_PASSWORD}
              element={
                <PrivateRoute auth={{ isAuthenticated: _id }}>
                  <Suspense fallback={<LoadingLogo />}>
                    <ChangePassword />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path={HALL_MODULE.MAIN}
              element={
                <PrivateRoute auth={{ isAuthenticated: _id }}>
                  <Suspense fallback={<LoadingLogo />}>
                    <Halls />
                  </Suspense>
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
