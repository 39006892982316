import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {URL} from '../../constants/routerPath'

// Action
export const getAllPartnerList = createAsyncThunk("getAllPartnerList", async () => {
  const token = sessionStorage.getItem("token")
  const header = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });

  const options = {
    method: 'GET',
    credentials: 'include',  
    headers: header,
  };
  const response = await fetch(`${URL}/api/v1/partner/getPartnerList`, options);
  return response.json();
});

export const addAllPartnerList = createAsyncThunk("addAllPartnerList", async (payload) => {
  const token = sessionStorage.getItem("token")
  const header = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });
  const options = {
    method: 'POST',
    headers: header,
    credentials: 'include',  
    body: JSON.stringify(payload)
  };
  const response = await fetch(`${URL}/api/v1/partner/insertPartner`, options);
  return response.json();
});

export const getByIdPartnerList = createAsyncThunk("getByIdPartnerList", async (payload) => {
  const token = sessionStorage.getItem("token")
  const header = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });
  const options = {
    method: 'GET',
    credentials: 'include',  
    headers: header,
  };
  const response = await fetch(`${URL}/api/v1/auth/user/${payload}`, options);
  return response.json();
});

export const getByPartnerId = createAsyncThunk("getByPartnerId", async (payload) => {
  const token = sessionStorage.getItem("token")
  const header = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });
  const options = {
    method: 'GET',
    headers: header,
  };
  const response = await fetch(`${URL}/api/v1/partner/getById/${payload}`, options);
  return response.json();
});

export const updatePartnerList = createAsyncThunk("updatePartnerList", async (payload) => {
  const token = sessionStorage.getItem("token")
  const header = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' });
  const options = {
    method: 'PATCH',
    headers: header,
    body: JSON.stringify(payload.data)
  };
  const response = await fetch(`${URL}/api/v1/admin/hall-master/${payload.url}`, options);
  return response.json();
});



export const addAllCustomerList = createAsyncThunk("addAllCustomerList", async (payload) => {
  const token = sessionStorage.getItem("token")
  const header = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });
  const options = {
    method: 'POST',
    headers: header,
    body: JSON.stringify(payload)
  };
  const response = await fetch(`${URL}/api/v1/customer/insertCustomer`, options);
  return response.json();
});

export const getCustomerList = createAsyncThunk("getCustomerList", async (payload) => {
  const token = sessionStorage.getItem("token")
  const header = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });
  const options = {
    method: 'GET',
    headers: header,
    body: JSON.stringify(payload)
  };
  const response = await fetch(`${URL}/api/v1/customer/getCustomerList`, options);
  return response.json();
});

export const updatePartner = createAsyncThunk("addAllCustomerList", async (payload) => {
  const token = sessionStorage.getItem("token")
  const header = new Headers({ 'Authorization': `Bearer ${token}` });
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });
  const options = {
    method: 'POST',
    headers: header,
    body: formData
  };
  const response = await fetch(`${URL}/api/v1/partner/updatePartner`, options);
  return response.json();
});


export const getCustomerByPartner = createAsyncThunk("getCustomerByPartner", async (payload) => {
  const token = sessionStorage.getItem("token")
  const header = new Headers({ 'Authorization': `Bearer ${token}` });
  const options = {
    method: 'get',
    headers: header,
  };
  const response = await fetch(`${URL}/api/v1/customer/getCustomerByPartner/${payload}`, options);
  return response.json();
});

const hallsSlice = createSlice({
  name: "PartnerList",
  initialState: {
    isLoading: false,
    isError: false,
    partnerIdLoading: true,
    partnerIdError: false,
    getAllData: [],
    addData: {},
    updateData: {},
    getDataById: {},
    customerData: [],
    partnerIdData: [],
    isLoadinggetByPartnerId: false,
    isErrorgetByPartnerId: false,
    customerList:[],
    isLoadingCustomerList:true,
    isErrorCustomerList:false,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPartnerList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllPartnerList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAllData = action.payload;
    });
    builder.addCase(getAllPartnerList.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(addAllPartnerList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addAllPartnerList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.addData = action.payload;
    });
    builder.addCase(addAllPartnerList.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(updatePartnerList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePartnerList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updateData = action.payload;
    });
    builder.addCase(updatePartnerList.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(getByIdPartnerList.pending, (state, action) => {
      state.isLoadinggetByPartnerId = true;
    });
    builder.addCase(getByIdPartnerList.fulfilled, (state, action) => {
      state.isLoadinggetByPartnerId = false;
      state.getDataById = action.payload;
    });
    builder.addCase(getByIdPartnerList.rejected, (state, action) => {
      state.isErrorgetByPartnerId = true;
    });
    builder.addCase(addAllCustomerList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addAllCustomerList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customerData = action.payload;
    });
    builder.addCase(addAllCustomerList.rejected, (state, action) => {
      state.isError = true;
    });
    // ----------------------------------------------------------------
    builder.addCase(getByPartnerId.pending, (state, action) => {
      state.partnerIdLoading = true;
    });
    builder.addCase(getByPartnerId.fulfilled, (state, action) => {
      state.partnerIdLoading = false;
      state.partnerIdData = action.payload;
    });
    builder.addCase(getByPartnerId.rejected, (state, action) => {
      state.partnerIdError = true;
    });
    //----------------------------------------------------------------
    builder.addCase(getCustomerList.pending, (state, action) => {
      state.isErrorCustomerList = true;
    });
    builder.addCase(getCustomerList.fulfilled, (state, action) => {
      state.isErrorCustomerList = false;
      state.customerList = action.payload;
    });
    builder.addCase(getCustomerList.rejected, (state, action) => {
      state.isErrorCustomerList = true;
    });
    //----------------------------------------------------------------
     builder.addCase(getCustomerByPartner.pending, (state, action) => {
      state.isLoadingCustomerList = true;
    });
    builder.addCase(getCustomerByPartner.fulfilled, (state, action) => {
      state.isLoadingCustomerList = false;
      state.customerList = action.payload;
    });
    builder.addCase(getCustomerByPartner.rejected, (state, action) => {
      state.isErrorCustomerList = true;
    });
    //----------------------------------------------------------------
  },
});

export default hallsSlice.reducer;
