import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { URL } from "../../constants/routerPath";

// Action
export const login = createAsyncThunk("login", async (data) => {
  const token = sessionStorage.getItem("token");
  const header = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });
  const options = {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      username: data.username,
      password: data.password,
    }),
  };
  const response = await fetch(`${URL}/api/v1/auth/login`, options);
  return response.json();
});

export const logout = createAsyncThunk("logout", async (data) => {
  const token = sessionStorage.getItem("token");
  const header = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });
  const options = {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      id: sessionStorage.getItem("id"),
    }),
  };
  const response = await fetch(`${URL}/api/v1/auth/logout`, options);
  return response.json();
});

export const changePassword = createAsyncThunk(
  "changePassword",
  async (data) => {
    const token = sessionStorage.getItem("token");
    const header = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });
    const options = {
      method: "POST",
      headers: header,
      credentials: "include",
      body: JSON.stringify({
        cpassword: data.cpassword,
        npassword: data.npassword,
        id: data.id,
      }),
    };
    const response = await fetch(`${URL}/api/v1/auth/updatePassword`, options);
    return response.json();
  }
);

export const checkSession = createAsyncThunk(
  "checkSession",
  async (data) => {
    const token = sessionStorage.getItem("token");
    const header = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });
    const options = {
      method: "POST",
      headers: header,
      credentials: "include",
      body:{},
    };
    const response = await fetch(`${URL}/api/v1/auth/checkSession`, options);
    return response.json();
  }
);

const authSlice = createSlice({
  name: "Login",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    changePassword: true,
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(login.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });

    builder.addCase(changePassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.changePassword = action.payload;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default authSlice.reducer;
