import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/auth";
import hallsReducer from "./slice/HallMaster";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    partner: hallsReducer,
  },
});
