// export const URL='http://localhost:8080'
export const URL='https://alice-fin-be.vercel.app'
export const COMMON_MODULE = {
    ROOT: "/",
    DASHBOARD: '/dashboard',
    PARTNER_PAGE: '/partner',
    CUSTOMER_PAGE: '/user/:username',
    VIEW_PARTNER: '/partner/view-partner',
    VIEW_CUSTOMERS: '/customer/view-customer',
    VIEW_PROFILE: '/partner/view-profile',
    PROFILE: '/profile/view-profile',
    CHANGE_PASSWORD: '/change-password'
};

export const AUTH_MODULE = {
    LOGIN: "/login",
    LOGOUT: "/logout",
    CHANGE_PASSWORD: "/change-password",
    FORGOT_PASSWORD: "/forgot-password",
};
export const HALL_MODULE = {
    MAIN: "/halls",
    ADD: "/halls/add-halls",
    EDIT: "/halls/edit-halls",
    VIEW: "/halls/view-halls",
};
